import * as React from 'react';
import {Spinner} from '../components/Spinner';
import {useAuth} from "../context/authContext";

const loadAuthenticatedApp = () => import(`./authenticated-app`);
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import(`./unauthenticated-app`));

export function Dashboard() {
    const isSSR = typeof window === `undefined`;
    const {isLoggedIn} = useAuth();
    React.useEffect(() => {
        // Load the authenticated side in the background while the user is filling out the login form.
        loadAuthenticatedApp();
    }, []);

    // React.Suspense doesn't yet work with Gatsby.
    return (
        <>
            {!isSSR && (
                <React.Suspense fallback={<Spinner />}>
                    {isLoggedIn() ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                </React.Suspense>
            )}
        </>
    )
}
